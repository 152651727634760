import React, { useEffect, useState } from "react";
import "./promos.css";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import CountdownMini from "../../../../components/countdown-mini/ConutDownMini";

export default function Promos() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState([]);
  const [actives, setActives] = useState([]);
  const [hovered, setHovered] = useState(false);

  const today = new Date();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/promos?populate=*&filters[public][$eq]=true&filters[lang][$eq]=English&sort=startsAt:asc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data);
        let activesDocs = data.data.filter((i) => {
          return new Date(i.attributes.endsAt) > today;
        });

        setActives(activesDocs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="promo-slider-c">
      {actives.length > 0 &&
        actives.slice(0, 3).map((item, index) => {
          let startDate = new Date(item.attributes.startsAt);
          let endDate = new Date(item.attributes.endsAt);
          if (endDate > today) {
            return (
              <div
                key={index}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={`promo-slide ${
                  startDate < today && endDate > today ? "shine" : ""
                } ${endDate < today ? "expired" : ""} ${
                  index == 1 ? "upcoming-promo-one" : ""
                } ${index == 2 ? "upcoming-promo-two" : ""}
                ${hovered && index !== 0 ? "promo-hovered" : ""}
                ${isMobile ? "promo-hovered" : ""}
                }`}
              >
                <img
                  src={SERVER_DOMAIN + item.attributes.bg.data.attributes.url}
                />
                {hovered && index !== 0 && (
                  <h4
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 20,
                      color: "#777",
                    }}
                  >
                    Coming Soon
                  </h4>
                )}
                {isMobile && index !== 0 && (
                  <h4
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 20,
                      color: "#777",
                    }}
                  >
                    Coming Soon
                  </h4>
                )}
                <h3>{index == 0 && item.attributes.title}</h3>
                {index == 0 && (
                  <>
                    {startDate > today ? (
                      <CountdownMini targetDate={startDate} />
                    ) : (
                      <div className="promo-active">Active</div>
                    )}
                  </>
                )}

                {index == 0 && (
                  <div className="promo-slide-dates">
                    <p>{startDate.toLocaleDateString("tr")}</p>
                    <p>-</p>
                    <p>{endDate.toLocaleDateString("tr")}</p>
                  </div>
                )}

                <p>{index == 0 && item.attributes.desc}</p>
                {index == 0 && (
                  <Link to={item.attributes.btnL} className="promo-slide-btn">
                    <img
                      src={
                        SERVER_DOMAIN +
                        item.attributes.btnBg.data.attributes.url
                      }
                    />
                  </Link>
                )}
              </div>
            );
          }
        })}

      {data.length > 0 &&
        data.map((item, index) => {
          let startDate = new Date(item.attributes.startsAt);
          let endDate = new Date(item.attributes.endsAt);

          if (endDate < today) {
            return (
              <div
                style={{
                  width: "85%",
                  margin: "0 auto",
                }}
                key={index}
                className={`promo-slide ${
                  startDate < today && endDate > today ? "shine" : ""
                } ${endDate < today ? "expired" : ""}`}
              >
                <img
                  src={SERVER_DOMAIN + item.attributes.bg.data.attributes.url}
                />
                <h3>{item.attributes.title}</h3>
                {startDate > today ? (
                  <CountdownMini targetDate={startDate} />
                ) : (
                  <>
                    {endDate > today ? (
                      <div className="promo-active">Active</div>
                    ) : (
                      <div className="promo-active">Expired</div>
                    )}
                  </>
                )}

                <div className="promo-slide-dates">
                  <p>{startDate.toLocaleDateString("tr")}</p>
                  <p>-</p>
                  <p>{endDate.toLocaleDateString("tr")}</p>
                </div>

                <p>{item.attributes.desc}</p>
                {startDate < today && endDate > today && (
                  <Link to={item.attributes.btnL} className="promo-slide-btn">
                    <img
                      src={
                        SERVER_DOMAIN +
                        item.attributes.btnBg.data.attributes.url
                      }
                    />
                  </Link>
                )}
              </div>
            );
          }
        })}
    </div>
  );
}
