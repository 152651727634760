import React, { useEffect, useState } from "react";
import "./choose-us.css";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import flame from "../../../../assets/icons/flame-icon.svg";
import bear from "../../../../assets/images/bear.svg";
import CheckIcon from "@mui/icons-material/Check";
import { API } from "../../../../assets/staticData";
import { Link } from "react-router-dom";

const apiKey = process.env.REACT_APP_API_KEY;

export default function ChooseUs() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/services-section`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res.data?.attributes?.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="choose-c">
      <div className="choose-content-c">
        <div className="choose-side-a">
          <h2>{data.heading}</h2>
          <p>{data.description}</p>
          <Link
            to={data.btnLink}
            className="hero-btn"
            style={{ marginLeft: 0 }}
          >
            <img src={flame} />
            <p>{data.btnText}</p>
          </Link>
        </div>
        <div className="choose-side-b">
          {data.cards && (
            <>
              <div className="choose-card-c" style={{ marginTop: 30 }}>
                <div
                  className="choose-card"
                  style={{ borderBottomRightRadius: 0 }}
                >
                  <div style={{ display: "flex", gap: 5 }}>
                    <img
                      className="home-card-icon"
                      src={require("../../../../assets/icons/advanced-home-icon.png")}
                    />

                    <h3>{data.cards[0]?.title}</h3>
                  </div>
                  <p>{data.cards[0]?.subTitle}</p>
                  {data.cards[0]?.link && (
                    <Link
                      to={data.cards[0]?.link}
                      className="choose-read-more-btn"
                    >
                      <p>Read More</p>
                    </Link>
                  )}
                </div>
                <div className="choose-card" style={{ borderTopLeftRadius: 0 }}>
                  <div style={{ display: "flex", gap: 5 }}>
                    <img
                      className="home-card-icon"
                      src={require("../../../../assets/icons/competivie-home-icon.png")}
                    />

                    <h3>{data.cards[2]?.title}</h3>
                  </div>
                  <p>{data.cards[2]?.subTitle}</p>
                  {data.cards[2]?.link && (
                    <Link
                      to={data.cards[2]?.link}
                      className="choose-read-more-btn"
                    >
                      <p>Read More</p>
                    </Link>
                  )}
                </div>
              </div>
              <div className="choose-card-c" style={{ marginTop: -30 }}>
                <div
                  className="choose-card"
                  style={{ borderTopRightRadius: 0 }}
                >
                  <div style={{ display: "flex", gap: 5 }}>
                    <img
                      className="home-card-icon"
                      src={require("../../../../assets/icons/professional-home-icon.png")}
                    />

                    <h3>{data.cards[1]?.title}</h3>
                  </div>
                  <p>{data.cards[1]?.subTitle}</p>
                  {data.cards[1]?.link && (
                    <Link
                      to={data.cards[1]?.link}
                      className="choose-read-more-btn"
                    >
                      <p>Read More</p>
                    </Link>
                  )}
                </div>
                <div
                  className="choose-card"
                  style={{ borderBottomLeftRadius: 0 }}
                >
                  <div style={{ display: "flex", gap: 5 }}>
                    <img
                      className="home-card-icon"
                      src={require("../../../../assets/icons/strength-home-icon.png")}
                    />

                    <h3>{data.cards[3]?.title}</h3>
                  </div>
                  <p>{data.cards[3]?.subTitle}</p>
                  {data.cards[3]?.link && (
                    <Link
                      to={data.cards[3]?.link}
                      className="choose-read-more-btn"
                    >
                      <p>Read More</p>
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <img className="choose-bg" src={bear} />
    </div>
  );
}
