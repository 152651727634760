import React, { useEffect, useState } from "react";
import "./map.css";
import map from "../../../../assets/images/map.svg";
import pulsingCircle from "../../../../assets/animations/pulsing-circle.json";
import mapCard from "../../../../assets/images/map-card-bg.svg";
import Lottie from "react-lottie";

export default function Map() { 
  const [cards, setCards] = useState([]);

  const dots = [
    {
      type: "Office",
      address: "ankara, try",
      amount: "2300K",
      top: "38.7%",
      left: "55%",
      locationURL: "",
    },
    {
      type: "Office",
      address: "london, uk",
      amount: "1800K",
      top: "29.5%",
      left: "45.6%",
      locationURL: "",
    },
    {
      type: "Office",
      address: "dubai, uae",
      amount: "1200K",
      top: "48%",
      left: "61%",
      locationURL: "",
    },
    {
      type: "Office",
      address: "tehran, iran",
      amount: "1200K",
      top: "41.6%",
      left: "60.2%",
      locationURL: "",
    },
    {
      type: "Office",
      address: "Saint Lucia",
      amount: "1200K",
      top: "54.5%",
      left: "29%",
      locationURL: "",
    },
    {
      type: "Office",
      address: "Saint Vincent and the Grenadines",
      amount: "1200K",
      top: "56.6%",
      left: "30.5%",
      locationURL: "",
    },
  ];

  const miniDot = [
    {
      title: "usa",
      top: "40%",
      left: "19%",
    },
    {
      title: "brazil",
      top: "66.5%",
      left: "29%",
    },
    {
      title: "brazil",
      top: "67%",
      left: "34%",
    },
    {
      title: "brazil",
      top: "73%",
      left: "32.5%",
    },
    {
      title: "turkey",
      top: "40.5%",
      left: "54.7%",
    },
    {
      title: "gorgeia",
      top: "37.9%",
      left: "58.5%",
    },
    {
      title: "iran",
      top: "43%",
      left: "59.9%",
    },
    {
      title: "saudi",
      top: "47.9%",
      left: "57.5%",
    },
    {
      title: "saudi",
      top: "51.1%",
      left: "60.2%",
    },
    {
      title: "russia",
      top: "27%",
      left: "60.2%",
    },
    {
      title: "russia",
      top: "21%",
      left: "69.5%",
    },
    {
      title: "russia",
      top: "25.8%",
      left: "80.2%",
    },
    {
      title: "china",
      top: "45%",
      left: "77%",
    },
    {
      title: "china",
      top: "43.5%",
      left: "71%",
    },
    {
      title: "india",
      top: "48%",
      left: "68%",
    },
    {
      title: "aus",
      top: "78%",
      left: "86.3%",
    },
  ];

  useEffect(() => {
    let showDocs = [];
    dots.map(() => {
      showDocs.push({ show: false });
    });
    setCards(showDocs);
  }, []);

  return (
    <div className="map-c">
      <div className="map-img-c">
        <img className="map-img" src={map} />
        {dots.map((item, index) => {
          return (
            <div
              key={index}
              className="map-pulsing-circle"
              style={{ top: item.top, left: item.left }}
              onMouseEnter={() =>
                setCards([
                  ...cards.map((item, idx) => {
                    if (idx === index) {
                      return { ...item, show: true };
                    } else {
                      return { ...item, show: false };
                    }
                  }),
                ])
              }
              onMouseLeave={() =>
                setCards([
                  ...cards.map((item, idx) => {
                    if (idx === index) {
                      return { ...item, show: false };
                    } else {
                      return item;
                    }
                  }),
                ])
              }
            >
              <Lottie
                width={"100%"}
                options={{
                  animationData: pulsingCircle,
                }}
              />

              <div
                style={{
                  width: 140,
                  height: 100,
                  top: -100,
                  left: -140,
                  opacity: cards[index]?.show ? 1 : 0,
                  visibility: cards[index]?.show ? "visible" : "hidden",
                  backgroundColor: "#fff",
                  position: "absolute",
                  transition: "0.5s",
                  overflow: "hidden",
                  boxShadow: "0 0 10px #00000040",
                  borderRadius: 7,
                  padding: 10,
                  textAlign: "left",
                  zIndex: 999,
                }}
              >
                <img
                  src={mapCard}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
                <div className="map-card-content">
                  <h5>{item.type}</h5>
                  <div>
                    <h4>{item.amount?.toUpperCase()}</h4>
                    <p>{item?.address?.toUpperCase()}</p>
                  </div>
                  <div style={{ position: "absolute", bottom: 0, right: 0 }}></div>
                </div>
              </div>
            </div>
          );
        })}
        {miniDot.map((item, index) => (
          <div
            className="map-mini-dot"
            key={index}
            style={{ top: item.top, left: item.left }}
          ></div>
        ))}
      </div>
    </div>
  );
}
