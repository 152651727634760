import React, { useEffect, useState } from "react";
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./category.css";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

export default function Category() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const url = window.location.href;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/categories?filters[category][$eq]=${searchParams.get(
            "category"
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchPosts() {
      try {
        const response = await fetch(
          `${API}/${
            url.includes("articles") ? "articles" : "analyses"
          }?filters[category][$eq]=${searchParams.get("category")}&populate=*`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
    fetchPosts();
  }, []);

  return (
    <div className="cat-c">
      {data.length > 0 && (
        <div className="cat-c-c">
          <h3>{data[0].attributes.category}</h3>
          <p>{data[0].attributes.desc}</p>

          <h4>Posts Related To This Category:</h4>

          <div className="help-articles-c">
            <div
              className="h-a-c-c in-cat-page"
              style={{
                justifyContent:
                  posts.length > 2 ? "space-between" : "flex-start",
              }}
            >
              {posts?.map((item, index) => (
                <div
                  className="h-a-i"
                  key={index}
                  onClick={() => {
                    const postTitle = item?.attributes?.title
                      ?.replace(/\s+/g, "-")
                      .replace("?", "");
                    navigate(
                      {
                        pathname: `/post/${postTitle}`,
                        search: createSearchParams({
                          id: item.id,
                        }).toString(),
                      },
                      { state: item }
                    );
                  }}
                >
                  <div className="h-a-i-img">
                    <img
                      src={
                        SERVER_DOMAIN +
                        item?.attributes?.image?.data?.attributes?.url
                      }
                    />
                  </div>

                  <div className="h-a-i-content">
                    <p className="blog-card-date">
                      {new Date(item.attributes.createdAt).toLocaleDateString(
                        "TR"
                      )}
                    </p>
                    <h3 className="blog-card-title">{item.attributes.title}</h3>
                    <p className="blog-card-des">
                      {item.attributes.subTitle.length > 100
                        ? item.attributes.subTitle.slice(0, 90) + "..."
                        : item.attributes.subTitle}
                    </p>
                  </div>

                  <div className="h-a-i-read flex">
                    Read
                    <ArrowCircleRightOutlinedIcon sx={{ fontSize: 20 }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
