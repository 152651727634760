import React, { useEffect, useState } from "react";
import Question from "../about/sections/question/Question";
import "./terms.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CustomScroll } from "react-custom-scroll";
import { API, SERVER_DOMAIN } from "../../assets/staticData";

import parse from "html-react-parser";
import { Link, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Terms({ seo }) {
  const [activeTab, setActiveTab] = useState("p");
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  let termsOn = searchParams.get("terms");

  useEffect(() => {
    if (termsOn) {
      setActiveTab("t");
    }
    async function fetchData() {
      try {
        const response = await fetch(`${API}/terms`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();

        setData(res.data.attributes.terms[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {seo[8]?.metaTitle ? seo[8]?.metaTitle : "Mishov Markets"}
        </title>
        <meta name="description" content={seo[8]?.metaDesc} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={seo[8]?.socialTitle} />
        <meta property="og:description" content={seo[8]?.socialDesc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={SERVER_DOMAIN + seo[8]?.socialImg} />
        <meta name="robots" content={seo[8]?.indexingRules} />
        <meta name="keywords" content={seo[8]?.keywords} />
        <script type="application/ld+json">{seo[8]?.schema}</script>
      </Helmet>
      <div className="terms-c">
        <div className="terms-header">
          <h3 onClick={() => setActiveTab("p")}>Privacy Policy</h3>
          <h3 onClick={() => setActiveTab("m")}>Anti Money Laundering Rules</h3>
          <h3 onClick={() => setActiveTab("t")}>Terms & Conditions</h3>
          <div
            className="terms-header-underline"
            style={{
              left:
                activeTab == "p"
                  ? "3.3%"
                  : activeTab == "m"
                  ? "28.7%"
                  : activeTab == "t"
                  ? "70.4%"
                  : 0,
              width:
                activeTab == "p"
                  ? "20%"
                  : activeTab == "m"
                  ? "35%"
                  : activeTab == "t"
                  ? "26%"
                  : 0,
            }}
          ></div>
        </div>

        <div className="terms-body">
          <img className="logo-terms"
            src={require("../../assets/images/logo-terms.png")}
            style={{
              position: "absolute",
              left: "23.5%",
              top: "20%",
              width: 400,
              zIndex: 0,
              opacity: 0.1,
            }}
          />
          <CustomScroll heightRelativeToParent="100%" allowOuterScroll>
            {data?.p && (
              <div
                className="content-inner"
                style={{
                  lineHeight: 1.9,
                  textAlign: "justify",
                  fontSize: 15,
                  zIndex: 1,
                  position: "relative",
                }}
              >
                {activeTab == "p" && parse(data.p)}
                {activeTab == "m" && parse(data.m)}
                {activeTab == "t" && parse(data.t)}
              </div>
            )}
          </CustomScroll>
        </div>
        <div style={{ marginTop: 20 }}>
          <Link
            target="_"
            className="terms-d-c flex"
            to={
              activeTab == "p"
                ? SERVER_DOMAIN + data.pFile
                : activeTab == "m"
                ? SERVER_DOMAIN + data.mFile
                : SERVER_DOMAIN + data.tFile
            }
          >
            <h3>Download PDF</h3>
            <FileDownloadIcon />
          </Link>
        </div>
      </div>
      <Question />
    </div>
  );
}
