import React, { useEffect, useState } from "react";
import "./market.css";
import { API, SERVER_DOMAIN } from "../../../assets/staticData";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";

export default function Market() {
  const [data, setData] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [ogAnalysis, setOGAnalysis] = useState([]);
  const [filter, setFilter] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [resCount, setResCount] = useState(1);

  const navigate = useNavigate();

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/articles?populate=*&pagination[pageSize]=6&filters[lang][$eq]=English&filters[public][$eq]=true&sort=createdAt:desc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchAnalysis() {
      try {
        const response = await fetch(
          `${API}/analyses?populate=*&filters[lang][$eq]=English&filters[public][$eq]=true&sort=createdAt:desc`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setAnalysis(res.data);

        setOGAnalysis(res.data);
        setResCount(res.meta.pagination.pageCount);

        let tabsDocs = [];
        res.data.forEach((i) => {
          const category = i.attributes.category;
          if (!tabsDocs.includes(category)) {
            tabsDocs.push(category);
          }
        });
        setTabs(tabsDocs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
    fetchAnalysis();
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          right: -35,
          top: "17%",
          cursor: "pointer",
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 25,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };
  const PervArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          left: -21,
          top: "17%",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 25,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PervArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  const handleFilter = (item) => {
    if (filter !== item) {
      setFilter(item);
      let dataDocs = ogAnalysis;
      let filteredData = dataDocs.filter((i) => i.attributes.category == item);
      setAnalysis(filteredData);
      let fpCount = Math.round(filteredData.length / 6);
      if (fpCount >= 1) {
        setResCount(fpCount);
      } else {
        setResCount(1);
      }
    } else {
      setFilter(null);
      setAnalysis(ogAnalysis);
    }
  };

  const handlePageChange = async (event, value) => {
    try {
      const response = await fetch(
        filter == null
          ? `${API}/analyses?pagination[pageSize]=6&pagination[page]=${value}&filters[lang][$eq]=English&filters[public][$eq]=true&sort=createdAt:desc`
          : `${API}/analyses?pagination[pageSize]=6&pagination[page]=${value}&filters[lang][$eq]=English&filters[public][$eq]=true&filters[category][$eq]=${filter}&sort=createdAt:desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const res = await response.json();
      setAnalysis(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="m-a-c flex">
      <div className="m-a-c-side-a">
        <h2>Market Analysis</h2>

        <div>
          <Slider {...settings} className="blog-slider">
            {tabs.map((item, index) => (
              <div
                key={index}
                className={`flex ${
                  filter == item ? "blog-tab-item-active" : "blog-tab-item"
                }`}
                onClick={() => handleFilter(item)}
              >
                {item}
              </div>
            ))}
          </Slider>
        </div>

        <div className="m-a-a-c flex-col">
          {analysis?.slice(0, 6).map((item, index) => (
            <div
              key={index}
              className="m-a-a-i"
              onClick={() => {
                const postTitle = item?.attributes?.title
                  ?.replace(/\s+/g, "-")
                  .replace("?", "");
                navigate(
                  {
                    pathname: `/analysis/${postTitle}`,
                    search: createSearchParams({
                      id: item.id,
                    }).toString(),
                  },
                  { state: item }
                );
              }}
            >
              <h3>{item.attributes.title}</h3>
              <p>
                {item.attributes.subTitle?.length > 150
                  ? item.attributes.subTitle?.slice(0, 145) + "..."
                  : item.attributes.subTitle}
              </p>
              <div className="flex tags-flex-c">
                <div>{item.attributes.category}</div>
                <div>
                  <AccessTimeIcon sx={{ fontSize: 16 }} />
                  {new Date(item.attributes.createdAt).toLocaleDateString(
                    "tr-TR"
                  )}
                </div>
                <div>
                  <InsertChartOutlinedRoundedIcon sx={{ fontSize: 17 }} />
                  Short Term Analysis
                </div>
              </div>
            </div>
          ))}
          <Pagination
            onChange={handlePageChange}
            count={resCount}
            shape="rounded"
            color="primary"
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  background: "var(--primary)",
                  color: "white",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="m-a-c-side-b">
        <h2>Articles</h2>
        <div className="m-a-c-b-c">
          {data?.slice(0, 6).map((item, index) => (
            <div
              key={index}
              className="m-a-c-b-i"
              onClick={() => {
                const postTitle = item?.attributes?.title
                  ?.replace(/\s+/g, "-")
                  .replace("?", "");
                navigate(
                  {
                    pathname: `/post/${postTitle}`,
                    search: createSearchParams({
                      id: item.id,
                    }).toString(),
                  },
                  { state: item }
                );
              }}
            >
              <img
                src={
                  SERVER_DOMAIN + item.attributes?.image?.data?.attributes?.url
                }
              />
              <h3>{item.attributes.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
