import React, { useEffect, useRef, useState } from "react";
import Hero from "./sections/hero/Hero";
import { useLocation } from "react-router";
import Summary from "./sections/summary/Summary";
import Related from "./sections/related/Related";
import Instructor from "./sections/instructor/Instructor";
import { API, SERVER_DOMAIN } from "../../assets/staticData";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Post() {
  const { state } = useLocation();
  const [postData, setPostData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const shareSec = useRef();
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    document.title = state?.attributes?.title
      ? state?.attributes?.title
      : postData?.attributes?.title;
  }, [state?.attributes?.title, postData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/articles/${searchParams.get("id")}?populate=*`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setPostData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [state?.id]);

  return (
    <div style={{ maxWidth: 1000, margin: "0 auto" }}>
      {postData ? (
        <>
          <Helmet>
            <meta name="description" content={postData?.attributes?.metaDesc} />
            <link rel="canonical" href={window.location.href} />
            <meta
              property="og:title"
              content={postData?.attributes?.socialTitle}
            />
            <meta
              property="og:description"
              content={postData?.attributes?.socialDesc}
            />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:image"
              content={
                SERVER_DOMAIN +
                postData?.attributes?.image?.data?.attributes?.url
              }
            />
            <meta name="robots" content={postData?.attributes?.indexingRules} />
            <meta
              name="keywords"
              content={postData?.attributes?.metaKeywords}
            />
            <script type="application/ld+json">
              {postData?.attributes?.schema}
            </script>
          </Helmet>
          <Hero item={state?.id ? state : postData} shareSec={shareSec} />
          <Summary
            item={state?.id ? state : postData}
            shareSec={shareSec}
            endPoint={"/articles"}
          />
          <Instructor item={state?.id ? state : postData} />
          <Related item={state?.id ? state : postData} />
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </div>
  );
}
