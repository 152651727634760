import React, { useEffect, useState } from "react";
import "./follow.css";
import { API } from "../../../../assets/staticData";

export default function Follow() {
  const [socials, setSocials] = useState({});
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchSocials() {
      try {
        const response = await fetch(`${API}/social-media`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const englishObject = data.data?.attributes?.socialMediaData?.find(
          (item) => item.language === "English"
        );
        setSocials(englishObject);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchSocials();
  }, []);

  return (
    <div className="ins-f-c">
      <img src={require("../../../../assets/images/NEON LIGHT-bg.png")} />

      <div className="ins-socials">
        {socials.youtube && (
          <div
            className="ins-social-i"
            onClick={() => window.open(socials.youtube)}
          >
            <img src={require("../../../../assets/icons/YOUTUBE-light.png")} />
          </div>
        )}
        {socials.instagram && (
          <div
            className="ins-social-i"
            onClick={() => window.open(socials.instagram)}
          >
            <img
              src={require("../../../../assets/icons/INSTAGRAM-light.png")}
            />
          </div>
        )}
        {socials.facebook && (
          <div
            className="ins-social-i"
            onClick={() => window.open(socials.facebook)}
          >
            <img src={require("../../../../assets/icons/FACEBOOK-light.png")} />
          </div>
        )}
        {socials.x && (
          <div className="ins-social-i" onClick={() => window.open(socials.x)}>
            <img src={require("../../../../assets/icons/X-light.png")} />
          </div>
        )}
        {socials.telegram && (
          <div
            className="ins-social-i"
            onClick={() => window.open(socials.telegram)}
          >
            <img src={require("../../../../assets/icons/TELEGRAM-light.png")} />
          </div>
        )}
        {socials.linkedIn && (
          <div
            className="ins-social-i"
            onClick={() => window.open(socials.linkedIn)}
          >
            <img src={require("../../../../assets/icons/LINKEDIN-light.png")} />
          </div>
        )}
      </div>

      <h2>Follow MISHOV MARKETS</h2>
      <h3>On Social Networks</h3>
    </div>
  );
}
