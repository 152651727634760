import React from "react";
import "./body.css";
import { SERVER_DOMAIN } from "../../../../assets/staticData";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export default function Body({ data }) {
  return (
    <div className="at-b-c">
      <div className="at-b-cards-c">
        {data.data &&
          data.data[0]?.cards.map((item, index) => (
            <div className="at-b-card" key={index}>
              <div className="flex">
                <img src={SERVER_DOMAIN + item.icon} />
                <h3>{item.title}</h3>
              </div>
              <p>{parse(item.subTitle)}</p>
            </div> 
          ))}
      </div>

      <div className="at-pm-c">
        <img src={data.images && SERVER_DOMAIN + data.images[0]?.paymentsImg} />
        <h3>{data.data && data.data[0].heading}</h3>
      </div>

      <div className="at-sytj-c">
        <div>
          <div>
            <h3>
              start your <br />
              <span>trade</span> journey
            </h3>
            <p>{data.data && data.data[0]?.desc}</p>
            <div className="at-sytj-btn-c">
              <Link to={data.data && data.data[0]?.btn1L}>
                {data.data && data.data[0]?.btn1T}
              </Link>
              <Link to={data.data && data.data[0]?.btn2L}>
                {data.data && data.data[0]?.btn2T}
              </Link>
            </div>
          </div>

          <img
            src={data.images && SERVER_DOMAIN + data.images[0]?.journeyImg}
          />
        </div>
      </div>
    </div>
  );
}
