import React from "react";
import "./hero.css";
import { SERVER_DOMAIN } from "../../../../assets/staticData";
import { Link } from "react-router-dom";

export default function Hero({ data }) {
  return (
    <div className="promos-c">
      <img
        className="promos-header-img"
        src={data.images && SERVER_DOMAIN + data.images[0].imgs[0]}
      />
      <h1>
        MISHOV MARKETS
        <br />
        Promotion Plans;
        <br />
        Earn up to
        <br />
        $1750
      </h1> 

      <Link
        to={data.data && data.data[0]?.btnL}
        className="hero-btn promos-h-btn"
        style={{ marginLeft: 0 }}
      >
        <img
          src={require("../../../../assets/icons/diamond-icon.png")}
         
        />
        <p>{data.data && data.data[0]?.btnT}</p>
      </Link>
    </div>
  );
}
