import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router";
import "./related.css";
import { createSearchParams } from "react-router-dom";

export default function Related() {
  const [data, setData] = useState([]);
const apiKey = process.env.REACT_APP_API_KEY;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API}/analyses?populate=*&filters[lang][$eq]=English&filters[public][$eq]=true`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`,
            },
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const res = await response.json();
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          right: -67,
          top: "42%",
          cursor: "pointer",
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 50,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };
  const PervArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          ...style,
          position: "absolute",
          left: -57,
          top: "42%",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon
          sx={{
            transition: "0.2s",
            fontSize: 50,
            color: "var(--primary)",
            "&:hover": {
              color: "var(--hover)",
            },
          }}
        />
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PervArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="related-p-c">
      <p>Relate Analysis
      </p>

      <Slider {...settings} className="related-slider">
        {data?.map((item, index) => (
          <div
            key={index}
            className="m-a-c-b-i related-post-item"
            onClick={() => {
              const postTitle = item?.attributes?.title
                ?.replace(/\s+/g, "-")
                .replace("?", "");
              navigate(
                {
                  pathname: `/post/${postTitle}`,
                  search: createSearchParams({
                    id: item.id,
                  }).toString(),
                },
                { state: item }
              );
            }}
          >
            <img
              src={
                SERVER_DOMAIN + item.attributes?.image?.data?.attributes?.url
              }
            />
            <h3>{item.attributes.title}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
}
