import React, { useEffect, useState } from "react";
import "./platforms.css";
import tower from "../../../../assets/images/tower.svg";

import windows from "../../../../assets/images/windows-btn.svg";
import google from "../../../../assets/images/google-btn.svg";
import apple from "../../../../assets/images/apple-btn.svg";
import apk from "../../../../assets/images/apk-btn.svg";
import linux from "../../../../assets/images/linux-btn.svg";
import mac from "../../../../assets/images/mac-btn.svg";
import macD from "../../../../assets/images/mac-btn-d.svg";
import windowsD from "../../../../assets/images/windows-btn-d.svg";
import { animated, useInView } from "@react-spring/web";
import { Link } from "react-router-dom";

export default function Platforms({ notHome }) {
  const [platform, setPlatform] = useState("m");

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: 100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: "-40% 0%",
    }
  );
  const [ref2, springs2] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: "-30% 0%",
    }
  );
  const [ref3, springs3] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 100,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-30% 0%",
    }
  );

  return (
    <div className="platform-c" style={{ minHeight: notHome ? "5vh" : null }}>
      {!notHome && (
        <>
          <h2>Platfroms</h2>
          <img
            src={require("../../../../assets/images/home-platforms.png")}
            className="home-platforms-img"
          />
        </>
      )}
      <div className="flex-col platform-btn-c">
        <div className="p-switch-c">
          <div className="flex" style={{ gap: 40 }}>
            <h3 onMouseEnter={() => setPlatform("m")}>Mobile</h3>
            <h3 onMouseEnter={() => setPlatform("d")}>Desktop</h3>
          </div>
          <div className="p-switch-bar">
            <div
              className="p-switch"
              style={{
                left: platform == "m" ? 0 : "56%",
                width: platform == "m" ? 100 : 111,
              }}
            ></div>
          </div>
        </div>

        <div
          style={{
            marginTop: 20,
            gap: 20,
            minHeight: 75,
          }}
          className="flex"
        >
          {platform == "m" ? (
            <>
              <Link
                target="_"
                to={"https://apps.apple.com/us/app/metatrader-5/id413251709"}
              >
                <img key={"apple"} src={apple} className="fade-in-up" />
              </Link>
              <Link
                target="_"
                to={
                  "https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&pli=1"
                }
              >
                <img key={"google"} src={google} className="fade-in-up" />
              </Link>

              {/* <Link target="_">
                <img key={"apk"} src={apk} className="fade-in-up" />
              </Link> */}
            </>
          ) : (
            <>
              <Link
                target="_"
                to="https://download.mql5.com/cdn/web/mishov.markets.ltd/mt5/mishovmarkets5setup.exe"
              >
                <img
                  style={{ width: 190, position: "relative", zIndex: 1 }}
                  key={"windows"}
                  src={windows}
                  className="fade-in-up"
                />
              </Link>
              {/* <Link
                target="_"
                to={
                  "https://download.mql5.com/cdn/web/mishov.markets.ltd/mt5/mishovmarkets5setup.exe"
                }
              >
                <img
                  style={{ width: 190, position: "relative", zIndex: 1 }}
                  key={"windowsD"}
                  src={windowsD}
                  className="fade-in-up"
                />
              </Link> */}
              <Link target="_" to="https://bit.ly/macmetatrader5">
                <img
                  style={{ width: 190, position: "relative", zIndex: 1 }}
                  key={"mac"}
                  src={mac}
                  className="fade-in-up"
                />
              </Link>
              {/* <Link target="_" to="https://bit.ly/macmetatrader5">
                <img
                  style={{ width: 190, position: "relative", zIndex: 1 }}
                  key={"macD"}
                  src={macD}
                  className="fade-in-up"
                />
              </Link> */}
              <Link target="_" to={"https://www.mql5.com/en/articles/625"}>
                <img
                  style={{ width: 190, position: "relative", zIndex: 1 }}
                  key={"linux"}
                  src={linux}
                  className="fade-in-up"
                />
              </Link>
            </>
          )}
        </div>
      </div>

      {!notHome && <img src={tower} className="tower-bg" />}
    </div>
  );
}
