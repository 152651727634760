import React, { useEffect, useState } from "react";
import "./follow.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import facebookIcon from "../../../../assets/icons/facebook-icon.svg";
import linkedInIcon from "../../../../assets/icons/linkedin-icon.svg";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";

export default function Follow({ data }) {
  const [socials, setSocials] = useState({});
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    async function fetchSocials() {
      try {
        const response = await fetch(`${API}/social-media`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const englishObject = data.data?.attributes?.socialMediaData?.find(
          (item) => item.language === "English"
        );
        setSocials(englishObject);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchSocials();
  }, []);

  return (
    <div className="blog-follow-c flex">
      <img src={require("../../../../assets/images/blue-background.png")} />
      <div className="b-f-side-a">
        <img src={data.images && SERVER_DOMAIN + data.images[0].socialsImg} />
      </div>
      <div className="b-f-side-b">
        <h2>Follow Mishov Markets</h2>
        <p>On Social Networks</p>

        <div className="footer-socials-c">
          {socials.youtube && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.youtube)}
            >
              <YouTubeIcon style={{ color: "#fff", fontSize: 40 }} />
            </div>
          )}
          {socials.instagram && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.instagram)}
            >
              <InstagramIcon style={{ color: "#fff", fontSize: 40 }} />
            </div>
          )}
          {socials.facebook && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.facebook)}
            >
              <img
                src={facebookIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          )}
          {socials.x && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.x)}
            >
              <XIcon style={{ color: "#fff", fontSize: 35 }} />
            </div>
          )}
          {socials.telegram && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.telegram)}
            >
              <TelegramIcon style={{ color: "#fff", fontSize: 40 }} />
            </div>
          )}
          {socials.linkedIn && (
            <div
              className="footer-social-i"
              onClick={() => window.open(socials.linkedIn)}
            >
              <img
                src={linkedInIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
