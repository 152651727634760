import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { API, SERVER_DOMAIN } from "../../../../assets/staticData";
import parse from "html-react-parser";

const symbols = [
  { label: "AUDUSD", cat: "Forex", symbol: "AUDUSD.a" },
  { label: "EURUSD", cat: "Forex", symbol: "EURUSD.a" },
  { label: "GBPUSD", cat: "Forex", symbol: "GBPUSD.a" },
  { label: "NZDUSD", cat: "Forex", symbol: "NZDUSD.a" },
  { label: "USDCAD", cat: "Forex", symbol: "USDCAD.a" },
  { label: "USDCHF", cat: "Forex", symbol: "USDCHF.a" },
  { label: "USDJPY", cat: "Forex", symbol: "USDJPY.a" },
  { label: "AUDCAD", cat: "Forex", symbol: "AUDCAD.a" },
  { label: "AUDCHF", cat: "Forex", symbol: "AUDCHF.a" },
  { label: "AUDJPY", cat: "Forex", symbol: "AUDJPY.a" },
  { label: "CADJPY", cat: "Forex", symbol: "CADJPY.a" },
  { label: "EURAUD", cat: "Forex", symbol: "EURAUD.a" },
  { label: "EURCAD", cat: "Forex", symbol: "EURCAD.a" },
  { label: "EURCHF", cat: "Forex", symbol: "EURCHF.a" },
  { label: "EURGBP", cat: "Forex", symbol: "EURGBP.a" },
  { label: "EURJPY", cat: "Forex", symbol: "EURJPY.a" },
  { label: "EURNZD", cat: "Forex", symbol: "EURNZD.a" },
  { label: "GBPAUD", cat: "Forex", symbol: "GBPAUD.a" },
  { label: "GBPCAD", cat: "Forex", symbol: "GBPCAD.a" },
  { label: "GBPCHF", cat: "Forex", symbol: "GBPCHF.a" },
  { label: "GBPJPY", cat: "Forex", symbol: "GBPJPY.a" },
  { label: "GBPNZD", cat: "Forex", symbol: "GBPNZD.a" },
  { label: "NZDCAD", cat: "Forex", symbol: "NZDCAD.a" },
  { label: "NZDCHF", cat: "Forex", symbol: "NZDCHF.a" },
  { label: "NZDJPY", cat: "Forex", symbol: "NZDJPY.a" },
  { label: "DAX40", cat: "Indices", symbol: "DAX40.a" },
  { label: "DJI30", cat: "Indices", symbol: "DJI30.a" },
  { label: "NASUSD", cat: "Indices", symbol: "NASUSD.a" },
  { label: "SP500", cat: "Indices", symbol: "SP500.a" },
  { label: "BRENT", cat: "Commodities", symbol: "BRENT.a" },
  { label: "CRUDE", cat: "Commodities", symbol: "CRUDE.a" },
  { label: "XAGUSD", cat: "Commodities", symbol: "XAGUSD.a" },
  { label: "XAUUSD", cat: "Commodities", symbol: "XAUUSD.a" },
  { label: "AUDNZD", cat: "Forex", symbol: "AUDNZD.a" },
  { label: "CADCHF", cat: "Forex", symbol: "CADCHF.a" },
  { label: "CHFJPY", cat: "Forex", symbol: "CHFJPY.a" },
];

export default function Hero({ data }) {
  const [tsVal, setTSVal] = useState();
  const [imVal, setimVal] = useState("");
  const [leverageVal, setleverageVal] = useState("");
  const [buysell, setBuySell] = useState("buy");
  const [selectedContractOption, setSelectedContractOption] = useState(100000);
  const [selectedSymbolOption, setSelectedSymbolOption] = useState(symbols[0]);
  const [selectedCurrency, setSelectedCurrency] = useState(1);
  const [result, setResult] = useState("0.00");
  const [error, setError] = useState(false);
  const [prices, setPrices] = useState([]);

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    if (tsVal !== null) {
      if (tsVal < 0.01) {
        setTSVal(0.01);
      }
      if (tsVal > 30) {
        setTSVal(30);
      }
    }
  }, [tsVal]);

  const selectedSymbol = selectedSymbolOption || {
    labe: "",
    cat: "",
    symbol: "",
  };

  const currencies = [
    {
      label: "USD",
    },
    {
      label: "EUR",
    },
    {
      label: "GBP",
    },
  ];

  useEffect(() => {
    if (selectedSymbol.cat == "Indices") {
      setSelectedContractOption(10);
    }
    if (selectedSymbol.cat == "Commodities") {
      setSelectedContractOption(1000);
    }
    if (selectedSymbol.label == "XAUUSD") {
      setSelectedContractOption(100);
    }
    if (selectedSymbol.label == "XAGUSD") {
      setSelectedContractOption(5000);
    }
    if (selectedSymbol.cat == "Forex") {
      setSelectedContractOption(100000);
    }
  }, [selectedSymbol]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${API}/global-market`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        let pricesDocs = [];
        data.data?.attributes?.data?.map((item) => {
          pricesDocs.push({
            symbol: item.Symbol,
            price: (item.Bid + item.Ask) / 2,
          });
        });
        setPrices(pricesDocs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const handleCalculate = () => {
    let selectedPrice = null;

    for (let i = 0; i < prices.length; i++) {
      if (prices[i].symbol === selectedSymbol.symbol) {
        selectedPrice = prices[i]?.price;
        break;
      }
    }

    if (
      tsVal == null ||
      imVal == null ||
      imVal == "" ||
      leverageVal == null ||
      leverageVal == ""
    ) {
      setError(true);
    } else {
      if (selectedSymbol.cat == "Forex") {
        if (selectedSymbol.symbol.includes("USD")) {
          if (selectedSymbol.label.startsWith("USD")) {
            // Margin is in USD, no need to convert
            let res = (tsVal * imVal * selectedContractOption) / leverageVal;
            setResult(res);
          } else if (selectedSymbol.label.endsWith("USD")) {
            let res =
              ((tsVal * imVal * selectedContractOption) / leverageVal) *
              selectedPrice;
            setResult(res);
          }
        } else {
          // No USD in the item
          const curs = symbols.filter((sy) => {
            const firstCurrency = selectedSymbol.label.slice(0, 3);
            return sy.label.includes(firstCurrency) && sy.label.includes("USD");
          });
          let itemToConvert = curs[0];

          let priceAgainstUSD;

          for (let i = 0; i < prices.length; i++) {
            if (prices[i].symbol === itemToConvert.symbol) {
              priceAgainstUSD = prices[i]?.price;
              break;
            }
          }
          let res =
            ((tsVal * imVal * selectedContractOption) / leverageVal) *
            priceAgainstUSD;
          setResult(res);
        }
      } else if (selectedSymbol.cat == "Indices") {
        let res = tsVal * imVal * selectedContractOption * selectedPrice;
        setResult(res);
      } else {
        let res =
          ((tsVal * imVal * selectedContractOption) / leverageVal) *
          selectedPrice;
        setResult(res);
      }
    }
  };

  const resetCalc = () => {
    setResult("0.00");
    setTSVal(null);
    setimVal("");
    setleverageVal("");
    setSelectedContractOption(100000);
  };

  return (
    <div className="plc-hero-c">
      <h2>MARGIN CALCULATOR</h2>

      <div className="plc-hero-c-c-o">
        <img src={data.images && SERVER_DOMAIN + data.images[0]?.headerImg} />

        <div className="flex plc-hero-c-c">
          <div className="plc-side-a flex-col">
            <div className="calc-c">
              <div>
                <div className="calc-row">
                  <p>Instrument</p>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    forcePopupIcon={false}
                    selectOnFocus
                    size="small"
                    id="combo-box-demo"
                    options={symbols}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    sx={{ width: 125 }}
                    renderInput={(params) => (
                      <TextField {...params} label="" sx={{ fontSize: 15 }} />
                    )}
                    value={selectedSymbolOption}
                    onChange={(event, newValue) => {
                      setSelectedSymbolOption(newValue);
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Account Currency</p>
                  <Select
                    sx={{
                      width: 125,
                      fontSize: 15,
                    }}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                      },
                    }}
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                  >
                    {currencies.map((item, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="calc-row">
                  <p>Trade Size (Lots)</p>
                  <input
                    className="calc-number-input"
                    type="number"
                    min={0}
                    max={30}
                    placeholder="0.01"
                    value={tsVal || ""}
                    onChange={(e) => {
                      let v = e.target.value;
                      setError(false);

                      if (v.length < 5) {
                        setTSVal(v);
                      }
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Initial Margin</p>
                  <input
                    className="calc-number-input"
                    type="number"
                    min={0}
                    placeholder="0.00"
                    value={imVal}
                    onChange={(e) => {
                      setimVal(e.target.value);
                      setError(false);
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Leverage</p>
                  <input
                    className="calc-number-input"
                    type="number"
                    min={1}
                    max={5000}
                    placeholder="0.00"
                    value={leverageVal || 1}
                    onChange={(e) => {
                      let v = e.target.value;
                      if (v < 5001) {
                        setleverageVal(v);
                      }
                      setError(false);
                    }}
                  />
                </div>
                <div className="calc-row">
                  <p>Contract Size</p>
                  <Select
                    sx={{
                      width: 125,
                      fontSize: 15,
                    }}
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedContractOption}
                    //  onChange={(e) => setSelectedContractOption(e.target.value)}
                  >
                    <MenuItem value={100000}>FX</MenuItem>
                    <MenuItem value={1000}>Oil</MenuItem>
                    <MenuItem value={10}>Index</MenuItem>
                    <MenuItem value={100}>Gold</MenuItem>
                    <MenuItem value={5000}>Silver</MenuItem>
                  </Select>
                </div>
                <div
                  className="calc-error flex"
                  style={{
                    height: error ? 30 : 0,
                  }}
                >
                  Please fill all the fields before calculating
                </div>
              </div>
              <div>
                <FormControl style={{ marginTop: 20, width: "80%" }}>
                  <RadioGroup
                    style={{ justifyContent: "space-evenly", marginLeft: -18 }}
                    defaultValue={"buy"}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="buy"
                      control={
                        <Radio
                          sx={{
                            color: "var(--primary)",
                            "&.Mui-checked": {
                              color: "var(--primary)",
                            },
                          }}
                        />
                      }
                      label="BUY"
                      onChange={(e) => setBuySell(e.target.value)}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="sell"
                      control={
                        <Radio
                          sx={{
                            color: "var(--primary)",
                            "&.Mui-checked": {
                              color: "var(--primary)",
                            },
                          }}
                        />
                      }
                      label="SELL"
                      onChange={(e) => setBuySell(e.target.value)}
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
                <div className="calc-btn-c">
                  <div className="calc-btn-o flex" onClick={resetCalc}>
                    Reset
                  </div>
                  <div className="calc-btn flex" onClick={handleCalculate}>
                    Calculate
                  </div>
                </div>
                <div className="calc-result-c">
                  <p>Margin:</p>
                  <div>
                    <h3
                      className="flex-col"
                      style={{
                        marginRight: 5,
                      }}
                    >
                      $
                    </h3>
                    <h3
                      style={{
                        fontWeight: "600",
                        fontSize: 20,
                      }}
                    >
                      {result !== "0.00"
                        ? Math.round((result + Number.EPSILON) * 100) / 100
                        : "0.00"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plc-side-b">
            {data.data && parse(`${data.data[0]?.header}`)}
          </div>
        </div>
      </div>
    </div>
  );
}
