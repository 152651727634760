import React from "react";
import { SERVER_DOMAIN } from "../../../../assets/staticData";
import "./hero.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import parse from "html-react-parser";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function Hero({ item, shareSec }) {
  const navigate = useNavigate();

  return (
    <div
      className="post-hero-c flex-col"
      style={{ maxWidth: 1000, marginLeft: "auto", marginRight: "auto" }}
    >
      <h1 style={{}}>{item?.attributes?.title}</h1>
      <img
        className="post-featured-image"
        style={{ marginTop: "2%" }}
        src={SERVER_DOMAIN + item?.attributes?.image?.data?.attributes?.url}
      />

      <div className="post-meta-c tags-flex-c">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate({
              pathname: `/analysis/categories`,
              search: createSearchParams({
                category: item.attributes.category,

              }).toString(),
            });
          }}
        >
          {item.attributes.category}
        </div>
        <div>
          <AccessTimeIcon sx={{ fontSize: 18 }} />
          {new Date(item.attributes.createdAt).toLocaleDateString("tr-TR")}
        </div>
        <div style={{ textTransform: "capitalize" }}>
          <svg
            style={{ width: 19 }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
          {item.attributes.author}
        </div>
        <div>
          <svg
            style={{ width: 19 }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
            />
          </svg>
          {item.attributes.likes}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            shareSec.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <svg
            style={{ width: 19 }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
            />
          </svg>
          Share
        </div>
      </div>

      <div className="post-content-c">{parse(item?.attributes?.content)}</div>
    </div>
  );
}
